const text = `
# IRTA presents SEN-ET in Spain

![IRTA presents SEN-ET in Spain](/images/irta_senet_spain.png 'IRTA presents SEN-ET in Spain')

Evapotranspiration in a vineyard derived using SEN-ET approach.

The SEN-ET project was recently presented by IRTA to Spanish and international stakeholders at two outreach events: Remote Sensing & Hydrology Symposium 2018 (RSHS2018), and a technical workshop entitled “Evapotranspiration, precision agriculture and Earth Observation”.

RSHS2018 had taken place in Córdoba (Spain) from the 8th till 10th of May 2018. During the symposium, Héctor Nieto (remote sensing specialist from IRTA) gave an oral presentation entitled “Fusion of Sentinel 2 and 3 imagery for estimating heat fluxes in a vineyard in California: The ESA’s Sentinels for Evapotranspiration (SEN-ET) project” during the “Observations of Water Cycle Components” session. The presentation introduced the SEN-ET project from a research perspective and included early results from applying the SEN-ET approach to an irrigated vineyard in California.

The “Evapotranspiration, precision agriculture and Earth Observation” technical workshop was organized by IRTA in collaboration with the Cartographic and Geologic Institute of Catalonia (ICGC) and took place in Barcelona (Spain) on May 15th 2018. During the workshop the first results of the SEN-ET project were introduced to the audience. In addition, IRTA had William P. Kustas (main researcher on the Two Source Energy Balance model) and Joseph G. Alfieri (expert in micrometeorology), both from the USDA-Agricultural Research Service, as invited speakers lecturing on the modelling and application of evapotranspiration for irrigation management in vineyards. The workshop was aimed to scientists, companies and general public and had an attendance of over 100 people.

For more details, see the [Presentation from Evapotranspiration, precision agriculture and Earth Observation workshop](/downloads/nieto_senet_barcelona_05.2018.pdf 'Presentation from Evapotranspiration, precision agriculture and Earth Observation workshop').
`;

export default text;
