
const text = `
# Project successfully completes Phase 1

On the 17th of January 2019 a review meeting was held to evaluate the outputs of Phase 1 of the project. Of main interest were:

- Validation dataset consisting of flux measurements from 11 flux towers representing various land cover classes and climatic conditions.
- The evaluation of the prototypes of three evapotranspiration models and two data sharpening approaches.

The meeting resulted in successful conclusion of Phase 1 of the project and authorisation to proceed with Phase 2. In the second phase, the best performing prototype of the evapotranspiration model and of the sharpening approach will be turned into an open-source SNAP plugin.
For more details of the evaluation process see the [Prototype Benchmarking Document](/downloads/prototype_evaluation_v1.3.pdf 'Prototype Benchmarking Document').
`;

export default text;
