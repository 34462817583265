import React from 'react';
import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Markdown from 'components/Markdown';

import section1 from 'assets/project/background.md.js';
import section2 from 'assets/project/objectives.md.js';
import section3 from 'assets/project/users.md.js';
import section4 from 'assets/project/consortium.md.js';
import section5 from 'assets/project/timeline.md.js';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  box: {
    maxWidth: 1080,
    backgroundColor: 'transparent',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  videoWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));

const sections = [section1, section2, section3, section4, section5];

const Project = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Box m="auto" p={2} className={classes.box}>
        <Grid container spacing={4} style={{ padding: '2vh 10px' }}>
          {/* {sections.map((section, sectionIndex) => (
            <Grid key={section.title} item xs={12}>
              <Typography
                variant={'h4'}
                className={landingpage.h4}
                style={{ textAlign: 'center' }}
                paragraph
              >
                {section.title}
              </Typography>
              {section.paragraphs.length > 0 &&
                section.paragraphs.map((paragraph, paragraphIndex) => (
                  <Typography
                    key={`section-${sectionIndex}-paragraph-${paragraphIndex}`}
                    variant={'body1'}
                    paragraph
                    style={{ textAlign: 'justify' }}
                  >
                    {paragraph}
                  </Typography>
                ))}
              {section.image && (
                <img src={SenetPoValley} alt="Sen-ET Po Valley" />
              )}
            </Grid>
          ))} */}
          {sections.map(section => (
            <Markdown
              className={classes.markdown}
              key={section.substring(0, 40)}
            >
              {section}
            </Markdown>
          ))}
        </Grid>
      </Box>
    </Paper>
  );
};

export default Project;
