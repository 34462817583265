import { directionColumn, justifyEnd } from 'styles/mixins';

const styles = {
  buttonsArea: {
    ...justifyEnd,
  },
  menuItem: {
    padding: 0,
    height: 'auto',
  },
  nameTag: {
    ...directionColumn,
    justifyContent: 'center',
  },
  linkItem: {
    width: 'inherit',

    lineHeight: '1.5em',
    textDecoration: 'none',
    color: 'inherit',
  },
};

export default styles
