import React from 'react';
import { compose } from 'recompose';
import { Link, withRouter } from 'react-router-dom';
import { Tab, Tabs, withStyles } from '@material-ui/core';

import styles from './styles';

const enhancer = compose(
  withRouter,
  withStyles(styles, { withTheme: true })
);

const tabs = [
  {
    value: '/',
    label: 'Home',
  },
  {
    value: '/project',
    label: 'Project',
  },
  {
    value: '/outputs',
    label: 'Outputs',
  },
];

const MenuArea = ({ classes }) => {
  const [tab, setTab] = React.useState('/');

  return (
    <div className={classes.buttonsArea}>
      <Tabs value={tab}>
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            className={classes.tab}
            value={tab.value}
            label={tab.label}
            component={Link}
            to={tab.value}
            onClick={() => setTab(tab.value)}
          />
        ))}
      </Tabs>
    </div>
  );
};

export default enhancer(MenuArea);
