const text = `
# Users

A number of key users are expected to benefit from the developed ET modelling application. Among them are:

1. Agriculturalists and organisations serving the needs of agriculturalists (such as companies specialising in serving satellite-derived information) will benefit by being able to easily produce accurate ET maps at field or regional scales for use in (for example) precision agriculture.

2. Scientists and research organisations working in the fields of agriculture, hydrology and remote sensing will benefit by being able to access the application’s source code and thus customise it for their needs and experiments.

3. Data providers (e.g. LSA SAF) will benefit by having access to customisable and scalable application able to operationally produce ET maps at a range of spatial resolutions and extents.

4. Regional, national and international governmental and non-governmental organisations and initiatives will benefit be being able to derive maps and statistics on agricultural water usage (e.g. to address SDG 6.4) and crop water stress (e.g. to contribute to the goals and target products of GEOGLAM.
`;

export default text;
