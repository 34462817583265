const text = `
# Scientific Publication

The scientific output of the project is summarized in an open access article published in Remote Sensing journal:

Guzinski, R., Nieto, H., Sandholt, I., Karamitilios, G., 2020. Modelling High-Resolution Actual Evapotranspiration through Sentinel-2 and Sentinel-3 Data Fusion. Remote Sensing 12, 1433.

The article is available from https://www.mdpi.com/2072-4292/12/9/1433
`;

export default text; 
