const text = `
# Prototype Benchmarking

At the beginning of the projects three evapotranspiration (ET) models and two approaches of sharpening thermal Sentinel-3 data using Sentinel-2 observations were selected based on literature review. The prototypes of all those methods have now been implemented and validated at 11 flux tower sites representing different land cover types and climatic conditions. The methods were also evaluated based on other criteria such as user friendliness or computational performance.

Based on this benchmarking exercise one ET model and one sharpening approach were selected to be implemented as an open-source SNAP software plugin. The selected ET model is the Two-Source Energy Balance (TSEB-PT) model and the sharpener is based on an ensemble of decision trees. The SNAP plugin will allow for field-scale ET estimation using predominantly Sentinel-2 and Sentinel-3 data and should become publicly available in the third quarter of 2019.

For more details please see the [Prototype Benchmarking Document](/downloads/prototype_evaluation_v1.3.pdf 'Prototype Benchmarking Document'), which can be downloaded by clicking [here](/downloads/prototype_evaluation_v1.3.pdf 'Prototype Benchmarking Document')
`;

export default text;
