import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Box, Container, Grid, makeStyles } from '@material-ui/core';

import Footer from 'components/Footer';
import Navigation from 'components/Navigation';
import NewsSidebar from 'components/NewsSidebar';
import Home from 'pages/Home';
import Project from 'pages/Project';
import Outputs from 'pages/Outputs';
import News from 'pages/News';

const useStyles = makeStyles(theme => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: 'auto',
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <Box>
      <Grid container space={0}>
        <Navigation />
      </Grid>

      <main>
        <Grid container className={classes.mainGrid}>
          <Grid item xs={12} md={8}>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/project">
                <Project />
              </Route>
              <Route exact path="/outputs">
                <Outputs />
              </Route>
              <Route exact path="/:newsId">
                <News />
              </Route>
            </Switch>
          </Grid>
          <Grid item xs={12} md={4}>
            <NewsSidebar />
          </Grid>
        </Grid>
      </main>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Footer />
        </Container>
      </footer>
    </Box>
  );
};

export default App;
