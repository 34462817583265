const text = `
# Sen-ET continues in [ET4FAO](https://et4fao.dhigroup.com)

The Sen-ET project has successfully concluded with the release of the SNAP plugin and recent publication of journal article summarizing the scientific output of the project. However, the evolution of method for deriving actual evapotranspiration at 20 m spatial resolution using Sentinel-3 and Sentinel-2 satellites will continue. A new project, executed by DHI GRAS and COMPLUTIG, will demonstrate the use of the developed method for national scale water use efficiency monitoring in the context of Sustainable Development Goal (SDG) 6. The project “[Increasing Crop Water Use Efficiency At Multiple Scales Using Sentinel Evapotranspiration – ET4FAO](https://et4fao.dhigroup.com)” is funded through ESA’s EO for Society Open Call and implemented in collaboration with the Food and Agriculture Organization (FAO).

The main objective of [ET4FAO](https://et4fao.dhigroup.com) is to provide knowledge and tools that will enable FAO (and the general international development community) to accurately and consistently monitor agricultural water use at continental, national and local scales using primarily Copernicus data sources, thus enabling global improvements in water use efficiency. FAO is the custodian agency for SDG indicators 6.4.1 and 6.4.2 and in charge of developing methodological guidelines for measuring progress on a national scale. With this in mind, FAO is maintaining a portal called WaPOR (https://wapor.apps.fao.org) to encourage the use of satellite data for water productivity monitoring. Among other EO-based products, WaPOR provides actual ET at decadal (10-days) and annual timesteps and at continental (250 m resolution), national (100 m resolution) and local (30 m resolution) spatial scales. Currently the primary data sources used to derive those products are the Terra and Aqua (MODIS sensor), PROBA-V and the Landsat satellites as well as GEOS-5 model for meteorological data. However, FAO is exploring the option of switching to Copernicus data due to the unprecedented continuity plans of the Sentinel satellites and the possibility of expanding the Sentinel constellation with high-resolution thermal mission in the future. This also ties in with the plans to expand the coverage of WaPOR from Africa and Near East to other continents.

In [ET4FAO](https://et4fao.dhigroup.com) we will evaluate the feasibility of switching WaPOR to Copernicus data sources, including Sentinel-2, Sentinel-3 satellites and meteorological data from Copernicus Climate Data Store, taking into account the accuracy of current and future ET products as well as continuity with the historical time-series already available in WaPOR. This will be achieved by running a study in which decadal and annual actual ET maps covering the whole Tunisia and Lebanon in 2019 and 2020 will be produced using both the existing WaPOR inputs and Copernicus inputs as well as using the ET model currently implemented in WaPOR (WaPOR-ET) and the ET model implemented in Sen-ET (TSEB-PT), as shown in figure below. This will be the first time an operational water consumption monitoring service derived with EO observations coming purely from Copernicus satellites will be delivered on multiple scales, including on local (field) scale.

![ET model implementation figure](/images/et_model_diagram.png 'ET model implementation figure')
`;

export default text;
