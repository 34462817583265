const text = `
# Methodology Review

As part of the project, a review of existing methodologies for estimation of evapotranspiration (ET) from remote sensing data as well as for sharpening Sentinel-3 thermal observations using Sentinel-2 images was carried out. The aim was to select methods for prototyping and benchmarking in the following phases of the project.

Since there was no ET model that outperformed the others based on the review, we have decided to include one model per large conceptual group for the prototyping: METRIC as one source energy balance model, TSEB-PT as two source energy balance model and ESVEP as hybrid contextual-energy balance model. In case of the sharpening approaches, two machine-learning based methods were selected: Artificial Neural Network and Decision Trees.

For more details please see the [Model Review Document](/downloads/sen-et_deliverable_mo_model_overview_final.pdf 'Model Review Document'), which can be downloaded by clicking [here](/downloads/sen-et_deliverable_mo_model_overview_final.pdf 'Model Review Document')
`;

export default text;
