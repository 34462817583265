import React from 'react';
import { Grid, Link, Typography, makeStyles } from '@material-ui/core';
import dhiLogo from 'assets/images/footer-logo-dhi-v1.jpg';
import irtaLogo from 'assets/images/footer-logo-irta-v1.jpg';
import sandholtLogo from 'assets/images/footer-logo-sandholt-v1.jpg';

const useStyles = makeStyles({
  logo: {
    boxSizing: 'border-box',
    '@media (max-width:600px)': {
      padding: '5% 10% 5px',
    },
  },
});

const logoWrapperStyle = {
  margin: 'auto',
  textAlign: 'center',
};

const Footer = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container>
        <Grid item xs={6} sm={2} style={logoWrapperStyle}>
          <Link href="https://www.dhi-gras.com/" target="_blank" rel="noreferrer">
            <span>Prime</span>
            <img src={dhiLogo} alt="DHI GRAS" className={classes.logo} />
          </Link>
        </Grid>
        <Grid item xs={6} sm={2} style={logoWrapperStyle}>
          <Link
            href="https://www.sandholt.eu/"
            target="_blank"
            rel="noreferrer"
          >
            <span>Partner</span>
            <img src={sandholtLogo} alt="Sandholt" className={classes.logo} />
          </Link>
        </Grid>

        <Grid item xs={6} sm={2} style={logoWrapperStyle}>
          <Link href="http://www.irta.cat/" target="_blank" rel="noreferrer">
            <span>Partner</span>
            <img src={irtaLogo} alt="IRTA" className={classes.logo} />
          </Link>
        </Grid>
      </Grid>
      <Typography variant="body2" color="textSecondary" align="center">
        {'Copyright © '}
        <Link color="inherit" href="https://www.dhi-gras.com/">
          DHI GRAS
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </>
  );
};

export default Footer;
