const text = `
# SNAP plugin released

We are pleased to release version 1.0 of the Sen-ET SNAP plugin, which is now available for download from the project website. While a beta-version of the plugin has been available for download since October, the software has now reached maturity to move out of the beta-phase and into regular release.

The Sen-ET SNAP plugin contains SNAP operators and graphs for the whole processing chain of deriving daily actual evapotranspiration (ETa) estimates at 20 m resolution using optical Sentinel-2 data and thermal Sentinel-3 data. The processing chain starts at downloading the required Sentinel-2 and Sentinel-3 images as well as Copernicus meteorological data, goes through the preparation of higher-level products required for ETa estimation, and finishes at estimating land-surface energy fluxes and upscaling the instantaneous latent heat flux to daily ETa.

The plugin follows modular design, in which each operator is represented by an individual Python script which can be accessed in SNAP through a thin interface. Therefore, the operators can be called either though SNAP GUI or SNAP GPT command line interface, or through Python command line interface or other Python code. The plugin is distributed with its own stand-alone Python Miniconda environment, to ease the installation and avoid conflicts with other Python distributions which might already be present on the user machines.

The software release is accompanied by the User Manual.

We hope that you will find the plugin useful.
`;

export default text;
