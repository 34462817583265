import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Markdown from 'components/Markdown';

import news1 from 'assets/news/news1.md.js';
import news2 from 'assets/news/news2.md.js';
import news3 from 'assets/news/news3.md.js';
import news4 from 'assets/news/news4.md.js';
import news5 from 'assets/news/news5.md.js';
import news6 from 'assets/news/news6.md.js';
import news7 from 'assets/news/news7.md.js';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  box: {
    maxWidth: 1080,
    backgroundColor: 'transparent',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  videoWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
}));

const news = { news1, news2, news3, news4, news5, news6, news7 };

const News = () => {
  const classes = useStyles();
  let { newsId } = useParams();

  return (
    <Paper className={classes.root}>
      <Box m="auto" p={2} className={classes.box}>
        <Grid container spacing={4} style={{ padding: '2vh 10px' }}>
          {news[newsId] ? (
            <Markdown className={classes.markdown}>{news[newsId]}</Markdown>
          ) : (
            <h1>404 - Page Not Found!</h1>
          )}
        </Grid>
      </Box>
    </Paper>
  );
};

export default News;
