import React from 'react';
import { compose } from 'recompose';
import { Link } from 'react-router-dom';
import { AppBar, Box, Grid, Toolbar, withStyles } from '@material-ui/core';

import MenuArea from './partials/MenuArea';
import Logo from 'components/Logo';

import styles from './styles';

const enhancer = compose(withStyles(styles, { withTheme: true }));

const Navigation = ({ classes }) => {
  return (
    <AppBar position="relative" className={classes.appBar}>
      <Grid container alignItems="center">
        <Grid item xs={4}>
          <Toolbar disableGutters>
            <Link to="/" className={classes.link}>
              <Box pl={1}>
                <Logo dark style={{ width: 110 }} />
              </Box>
            </Link>
            {/* <Typography variant="h6" color="primary" noWrap>
              sen-et
            </Typography> */}
          </Toolbar>
        </Grid>
        <Grid item xs={8}>
          <MenuArea />
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default enhancer(Navigation);
