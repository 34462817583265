const text = `
# Objectives

The goal of this project is to develop an open-source software application for accurate, pre-operational modelling of instantaneous evapotranspiration (and other land-surface energy fluxes) at high (tens of meters) and medium (1 km) spatial resolutions with the use of observations coming from Sentinel-3 (medium resolution thermal and optical) and Sentinel-2 (high resolution optical) satellites. The project will focus on agricultural applications of ET but will also involve evaluating the model output in other land-covers (e.g. forest). The fluxes should be modelled at the highest possible spatial resolution without sacrificing the output accuracy.

An additional objective is to provide inputs for requirements gathering and design processes of a possible future ESA thermal mission. The focus of those inputs should be on ET estimation (and other related agricultural applications) and they should highlight the possibilities provided by, and limitations of, the current observational capabilities provided by
the Sentinel constellation.

Those objectives will be achieved through:

1.  Reviewing of existing methodologies for estimation of ET and for bridging the spatial gap between S2 and S3 observations.
2.  Prototyping and benchmarking the most promising approaches and evaluating the outputs in a number of ecosystems.
3.  Fully implementing the best performing approach as an open-source software application.
`;

export default text;
