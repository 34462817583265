const styles = {
  root: {
    width: '50px',
    height: 'auto',
    borderRadius: '0',
    marginLeft: '15px',
    marginTop: '-5px',
    marginRight: '15px',
  },
}

export default styles;
