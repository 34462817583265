const text = `
# SEN-ET approach for field scale ET estimation evaluated in a recent publication

A recent article in the “Science and Applications with Sentinel-2” special issue of Remote Sensing of Environment journal has evaluated the SEN-ET approach for field scale evapotranspiration (ET) estimation. The article, written by the members of the SEN-ET consortium, looked at the accuracy of ET derived with sharpened low-resolution land surface temperature (LST) observations and compared it with the accuracy of ET derived with high-resolution LST. In that study, MODIS sensor on board of Terra satellite was used as the low-resolution data source, while Landsat observations were used as the high-resolution data source. The results indicate that in an agricultural setting the ET obtained using the two data sources is of comparable accuracy when compared against flux tower measurements and that there is a strong spatial and temporal agreement between the two ET maps, even tough larger range of ET values were obtained when high-resolution LST was used as an input. The presented methodology is readily transferable to Sentinel-3 and Sentinel-2 satellite observations.

The article is freely available through open access.

Reference: Guzinski, R., & Nieto, H. (2019). Evaluating the feasibility of using Sentinel-2 and Sentinel-3 satellites for high-resolution evapotranspiration estimations. Remote Sensing of Environment, 221, 157–172. https://doi.org/10.1016/j.rse.2018.11.019

![Spatial comparison of ET](/images/news2_et_spatial_comparison.png 'Spatial comparison of ET')

Figure from the article, showing spatial comparison of ET derived with low-resolution LST, sharpened low-resolution LST and high-resolution LST.
`;

export default text;
