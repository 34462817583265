import React from 'react';
import { Link } from "react-router-dom"
import { Box, Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { landingpageStyle } from 'styles/landingpage';
import SenetPoValley from 'assets/images/sen-et_po_valley.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  videoWrapper: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '56.25%',
  },
  link: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
    fontWeight: 500,
    textDecoration: "none"
  }
}));

const Home = () => {
  const classes = useStyles();
  const landingpage = landingpageStyle();

  return (
    <Paper className={classes.root}>
      <Box m="auto" p={2} className={landingpage.box}>
        <Grid container spacing={4} style={{ padding: '2vh 0' }}>
          <Grid item xs={12}>
            <Typography variant={'h4'} className={landingpage.h4} paragraph>
              Sen-ET Introduction
            </Typography>
            <Typography variant={'body1'} paragraph>
              Satellite remote sensing of evapotranspiration is an essential
              part of the global observation system and provides inputs for
              agriculture, water resources management, weather forecasts,
              climate studies and many other applications. Easy access to
              reliable estimations of Evapotranspiration (ET) is considered a
              key requirement within these domains, and ET holds a vast
              potential to assist in the current attempts of meeting several of
              the UN Sustainable Development Goals (SDG), in particular SDG2 –
              zero hunger, SDG6 – clean water and sanitation and SDG13 – Climate
              action.
            </Typography>
            <Typography variant={'body1'} paragraph>
              In this context, the European Space Agency (ESA) has funded the
              Sentinels for Evapotranspiration (Sen-ET) project. The main
              objective of Sen-ET was to develop an optimal methodology for
              estimating ET at fine (tens of meters) spatial scale, based on
              synergistic use of Sentinel 2 and Sentinel 3 satellites’
              observations. The final methodology was implemented as an open
              source plugin to SNAP software and is available freely to all
              users.
            </Typography>
            <Typography variant={'body1'} paragraph>
              The use of the developed method for national scale SDG monitoring
              will be demonstrated in a new project “<a href="https://et4fao.dhigroup.com" className={classes.link} target="_blank" rel="noopener noreferrer">Increasing Crop Water Use
              Efficiency At Multiple Scales Using Sentinel Evapotranspiration –
              ET4FAO</a>{' '}” funded through ESA’s EO for Society Open Call. Throughout{' '}
              <a href="https://et4fao.dhigroup.com" className={classes.link} target="_blank" rel="noopener noreferrer">ET4FAO</a>{' '} further refinements to the Sen-ET method, as well as bug
              fixes, will be contributed back to Sen-ET GitHub repositories. For
              further details about <a href="https://et4fao.dhigroup.com" className={classes.link} target="_blank" rel="noopener noreferrer">ET4FAO</a>{' '} project, see the news update.
            </Typography>
            <Typography variant={'body1'} paragraph>
              For further details, go and see the{' '}
              <Link to="/project" className={classes.link}>
                Project page
              </Link>{' '}
              or contact the consortium.
            </Typography>
            <img src={SenetPoValley} alt="Sen-ET Po Valley" />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default Home;
