const text = `
# DHI GRAS presents SEN-ET at IGARSS

The 2018 IEEE International Geoscience and Remote Sensing Symposium (IGARSS) took place in Valencia from 22 to 27 of July. The SEN-ET consortium was represented by DHI-GRAS with Radoslaw Guzinski (remote sensing expert) presenting work which precedes, but is relevant to, SEN-ET and briefly introducing the project.

Despite the Friday morning time-slot the presentation was well attended and concluded with a lively question and answer session.

The presentation from IGARSS as well as the conference proceedings paper can be downloaded below:

- [IGARSS presentation](/downloads/radoslaw_guzinski_sen_et_igarss.pdf 'IGARSS presentation')
- [IGARSS proceedings paper](/downloads/guzinski_validation_paper_2018.pdf 'IGARSS proceedings paper')

![DHI GRAS presents SEN-ET at IGARSS](/images/dhi_gras_senet_igarss.png 'DHI GRAS presents SEN-ET at IGARSS')

Figure from the proceedings paper: left panel shows Sentinel-2 true colour composite of the area close to study site on 24.09.2017, while middle and right panels show respectively fine scale and coarse scale instantaneous latent heat flux estimates obtained during Sentinel-3 overpass on 23.09.2017.
`;

export default text;
