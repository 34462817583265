const text = `
# Project Consortium

[DHI GRAS](https://web.archive.org/web/20191221124722/https://www.dhi-gras.com/), a Danish not-for-profit private company specialized in remote sensing of the environment and development of open source applications and software;

[IRTA](https://web.archive.org/web/20191219151817/http://www.irta.cat/en/), a Spanish research institute owned by the Government of Catalonia, with specialist knowledge about the use of remote sensing for ET modelling

[Sandholt ApS](https://web.archive.org/web/20180402193909/http://www.sandholt.eu/), a Danish SME, relying on a 25-year record of research in environmental remote sensing.
`;

export default text;
