import { directionRow, justifySpaceBetween } from 'styles/mixins';

const themeObject = theme => ({
  appBar: {
    ...directionRow,
    ...justifySpaceBetween,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#F2F5F7',
    borderBottom: '2px solid #DBE4E9;',
    color: theme.palette.primary.main,
    padding: '0px 16px;',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  navBar: {
    marginLeft: 'auto',
    marginRight: 'right',
  },
  navButton: {
    marginLeft: -12,
    marginRight: 20,
    textDecoration: 'none',
    color: 'white',
  },
  hide: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    color: 'inherit',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
});

export default themeObject
