import React from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Link as MuiLink,
  Paper,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { landingpageStyle } from 'styles/landingpage';
import pdfIcon from 'assets/images/pdf-icon.png';
import pdfSrc from 'assets/downloads/sen-et-user-manual-v1.1.0.pdf';
import zipIcon from 'assets/images/zip-icon.png';
import zipSrc from 'assets/downloads/Sen-ET-plugin-v1.0.1.zip';

const downloadables = [
  {
    href: zipSrc,
    title: 'SNAP plugin',
    img: zipIcon,
    alt: 'zip icon',
  },
  {
    href: pdfSrc,
    title: 'User manual',
    img: pdfIcon,
    alt: 'pdf icon',
  },
];

const useStyles = makeStyles(theme => ({
  icon: {
    width: 52,
    margin: '0 auto',
  },
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing(3),
  },
}));

const news = [
  { title: 'Sen-ET continues in ET4FAO ', url: 'news7' },
  { title: 'SNAP plugin released', url: 'news6' },
  { title: 'Recent and upcoming outreach activities', url: 'news1' },
  { title: 'Project successfully completes Phase 1', url: 'news3' },
  {
    title:
      'SEN-ET approach for field scale ET estimation evaluated in a recent publication',
    url: 'news2',
  },
  { title: 'DHI GRAS presents SEN-ET at IGARSS', url: 'news5' },
  { title: 'IRTA presents SEN-ET in Spain', url: 'news4' },
];

const NewsSidebar = props => {
  const classes = useStyles();
  const landingpage = landingpageStyle();

  return (
    <>
      <Paper elevation={0} className={classes.sidebarAboutBox}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.sidebarSection}
        >
          About
        </Typography>
        <Typography className={classes.sidebarSection}>
          The main objective of Sen-ET was to develop an optimal methodology for
          estimating evapotranspiration at fine (tens of meters) spatial scale,
          based on synergistic use of Sentinel 2 and Sentinel 3 satellites’
          observations
        </Typography>
      </Paper>
      <Paper elevation={0} className={classes.sidebarAboutBox}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.sidebarSection}
        >
          News
        </Typography>
        {news.map(article => (
          <MuiLink
            display="block"
            variant="body1"
            to={article.url}
            key={article.title}
            component={Link}
            style={{ paddingBottom: '5px' }}
          >
            {article.title}
          </MuiLink>
        ))}
      </Paper>
      <Paper elevation={0} className={classes.sidebarAboutBox}>
        <Typography
          variant="h6"
          gutterBottom
          className={classes.sidebarSection}
        >
          Download resources
        </Typography>
        <Typography className={classes.sidebarSection}>
          Note: The SNAP plugin is no longer officially supported. For community support please see the relevant section of the SNAP Forum: <a href="https://forum.step.esa.int/c/snap/sen4et/37">https://forum.step.esa.int/c/snap/sen4et/37</a>.
        </Typography>
        <Grid container spacing={4}>
          {downloadables &&
            downloadables.map(({ href, title, img, alt }) => (
              <Grid
                item
                xs={12}
                sm={6}
                key={title}
                style={{ textAlign: 'center' }}
              >
                <a href={href} download>
                  <Typography
                    style={{
                      textAlign: 'center',
                      fontSize: 16,
                      paddingBottom: 16,
                    }}
                    className={landingpage.h4}
                  >
                    {title}
                  </Typography>
                  <img src={img} alt={alt} className={classes.icon} />
                </a>
              </Grid>
            ))}
        </Grid>
      </Paper>
    </>
  );
};

export default NewsSidebar;
