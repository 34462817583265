import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { ThemeProvider } from '@material-ui/core';
import DHITheme from './styles/theme';

import 'typeface-roboto';
import './index.css';

ReactDOM.render(
  <Router basename="/">
    <ThemeProvider theme={DHITheme}>
      <App />
    </ThemeProvider>
  </Router>,
  document.getElementById('root')
);
