import React from 'react';
import { compose } from 'recompose';
import { Avatar as MaterialAvatar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import SenetLogo from 'assets/images/senet_logo.png';
// import DarkLogo from 'assets/images/DHI_Logo_Dark.png';
// import BlueLogo from 'assets/images/DHI_Logo_Blue.png';

import styles from './styles';

const enhancer = compose(withStyles(styles));

const Avatar = enhancer(MaterialAvatar);

const Logo = ({ dark = false, ...rest }) => (
  <Avatar alt="Sen-ET Logo" src={SenetLogo} {...rest} />
);

export default Logo;
