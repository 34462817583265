const text = `
# Software

One of the main project outputs is a tool for estimating evapotranspiration based on Sentinel-2 and Sentinel-3 data.

The toolbox is a plugin to the ESA SNAP http://step.esa.int/main/toolboxes/snap/ software, enabling the use of the SEN-ET algorithms within SNAP. You can also execute the processing as python processing outside SNAP through the command line.

**Note: The SNAP plugin is no longer officially supported. For community support please see the relevant section of the SNAP Forum: [https://forum.step.esa.int/c/snap/sen4et/37](https://forum.step.esa.int/c/snap/sen4et/37).**

The toolbox and user manual can be downloaded using the links below:

- [Toolbox](/downloads/Sen-ET-plugin-v1.0.1.zip 'SNAP Toolbox')
- [User manual](/downloads/sen-et-user-manual-v1.1.0.pdf 'User manual')
`;

export default text;
